@font-face {
  font-family: 'Unica';
  src:  url('/src/fonts/Unica77\ Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


*, *::before, *::after {
  box-sizing: border-box;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

img {

}

html, body, .app, .app>div {
  height: 100%;
  }

  html {
    width: 100%;
    
  }

.App {
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Unica", sans-serif;
  line-height: 1;
}

p {
  margin: 0;
}

a {
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: rgb(138, 89, 84);
}

.portrait {
  min-width: auto;
  width: 100% !important;
  max-width: 2500px;
  margin-bottom: 0px;

}

.landscape {
  min-width: auto;
  max-height: 100vh !important;
  max-width: 100%;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
}

.header {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 20px;
  font-size: 15px;
}

.projectInfo {
position: absolute;
top: 0;
font-size: 11px;
padding: 3px;
mix-blend-mode: difference;
color: #fff;
}

.projectWrapper {
  position: relative;
  padding: 0px;
  clear: both;;
}

.projectList {
  display: grid;
  flex-direction: column;
}

.loadable {
  padding: 0;
  line-height: 0;

}

.control {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-bottom: 10px;
  color: #494646;
  font-size: 15px;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 1;
  mix-blend-mode: difference;
  transition: ease all 300ms;
  transition-delay: 100ms;
  cursor: pointer;


}

.tagsMenu {
  width: 100%;
  clear: both;
  display: flex;
}

.filterListItem {

display: block;}

.holding {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;

  position: fixed;

}

.fill {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -999;
  transform: translate(-50%, -50%);
}

.fill2 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
min-height: 100%;
  transform: translate(-50%, -50%);
  max-width: none;  /* Prevents the image from being constrained by its parent's width */

}

.flex50 {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: row;

}
.child {
  width: 100%;
  position: relative;
}

.hero {
  position: fixed;
  z-index: 9999;
  font-size: 30px;
  text-align: center;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center; /* Align items to the bottom */
justify-content: center;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
}
.center {
  width: 100%;
  text-align: center;
}

.wrap {
  width: 100%;
}

.bar {
  position: fixed;
  top: 20px;
  left: 20px;
  right: 20px;
  z-index: 99999;
  height: 26px;
  background-color: #c4ec24;
  cursor: pointer;

}
.bar:hover {
  background-color: rgb(138, 89, 84)!important;

}

.Info {
  font-size: 30px;
  position: fixed;
  z-index: 99999;
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Align items to the bottom */
  padding: 20px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  color: rgb(138, 89, 84);
}

.small {
  color: #c4ec24;
  font-size: 14px;
}


@media (max-width: 600px) {

  .fill2 {

    width: auto;
height: 100%;
   
  }

  .Info {
    font-size: 26px;
  }

}

@media only screen
and (min-device-width: 20px)
and (max-device-width: 600px)
and (-webkit-min-device-pixel-ratio: 2) {

  .fill2 {

    width: auto;
height: 100%;
   
  }

}

