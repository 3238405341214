p {
    margin: 0;
}

.content {
    padding-bottom:  0px;
 
  }
  .content p {

 
  }
  .content p::after {

 
  }
  
.wrap {
  background-color:rgb(255, 101, 247);

}
  
  .accordion {
    overflow: hidden;
    position: relative;
    padding-bottom: 0px;
    width: 580px;
    margin-left: auto;
    margin-right: auto;
font-size: 23px;
line-height: 1.1;
padding: 3px;
  }
  
  .white {
    content: "";
    height: 80px;
    width: 100%;
    background: linear-gradient(0deg, rgb(255, 101, 247) 0%, rgba(255, 101, 247, 0.85) 30%, rgba(255, 101, 247, 0) 100%);
    position: absolute;
    bottom: 0;
  }
  